<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <!-- BEGIN: Logo -->
          <router-link
            :to="{ name: 'dashboard' }"
            tag="a"
            class="intro-x flex items-center pl-5 pt-4"
          >
            <img
              alt="Univ MDP"
              class="w-6"
              src="https://api.mdp.ac.id/public/assets/logo/logo-umdp-light.png"
            />
            <span class="hidden xl:block text-white text-lg ml-3">
              <span class="font-medium">Universitas</span> Multi Data Palembang
            </span>
          </router-link>
          <!-- END: Logo -->
          <div class="my-auto">
            <img
              alt="Universitas MDP"
              class="-intro-x w-1/2 -mt-16"
              src="https://api.mdp.ac.id/public/assets/logo/logo-umdp-light.png"
            />
            <div
              class="-intro-x text-white font-medium text-3xl leading-tight mt-10"
            >
              {{ title }}
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Silakan Login dengan user LDAP anda!
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <form
          class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0"
          @submit.prevent="submit"
        >
          <!-- BEGIN: Login Form -->
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Sign In
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              Silakan Login dengan user LDAP anda!
            </div>
            <div v-if="showError" class="intro-x mt-8">
              <AlertFailed :message="loginError" />
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="model.uid"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="UID"
                required
              />
              <input
                v-model="model.password"
                type="password"
                required
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Password"
              />
            </div>
            <div
              v-if="!loadingStatus"
              class="intro-x mt-4 xl:mt-5 text-center xl:text-left"
            >
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                Login
              </button>
            </div>

            <div v-if="loadingStatus" class="intro-x mt-4">
              <LoadingSpinner :message="'Logging in... Please wait...'" />
            </div>
          </div>
          <!-- END: Login Form -->
        </form>
      </div>
    </div>

    <div
      id="pickteam-modal"
      class="modal"
      :class="showPick ? 'overflow-y-auto show' : 'hide'"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="false"
      :style="
        showPick
          ? 'margin-top: 0px; margin-left: 0px; padding-left: 0px; z-index: 10000;'
          : ''
      "
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
              <div class="text-3xl mt-5">Silakan Login Sebagai</div>
            </div>
            <div class="px-5 pb-8 text-center">
              <ul class="inline-flex space-x-4">
                <li v-for="team in teams" :key="team.id">
                  <button
                    type="button"
                    class="btn btn-lg btn-success"
                    @click="setTeam(team.id)"
                  >
                    {{ team?.name }}
                  </button>
                </li>
              </ul>
            </div>
            <div
              class="p-5 text-center border-t border-gray-200 dark:border-dark-5"
            >
              <button
                class="text-theme-1 dark:text-theme-10"
                @click="cancelPick"
              >
                Batal dan Login sebagain user lain
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { mapActions } from "vuex";
import AlertFailed from "../../components/alerts/AlertFailed";
import { cpname } from "/src/libs/computer-name";
import LoadingSpinner from "@/components/loading-spinner/Main";

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertFailed,
  },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      model: {
        uid: "",
        password: "",
        apps:
          process.env.VUE_APP_NAME + "@" + cpname() + ":" + process.platform,
      },
      showError: false,
      showPick: false,
    };
  },
  computed: {
    loginError() {
      return this.$store.state.auth.error;
    },
    pickTeam() {
      return this.$store.getters["auth/pickTeam"];
    },
    teams() {
      return this.$store.getters["userdata/teams"];
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    loginError() {
      this.showError = true;
    },
    pickTeam: {
      handler(nv) {
        //if (nv == true) cash("#pickteam-modal").modal("show");
        if (nv) this.showPick = true;
      },
      immediate: true,
    },
  },
  mounted() {
    this.showPick = false;
  },
  methods: {
    ...mapActions({
      LogIn: "auth/LogIn",
      SetTeam: "auth/SetTeam",
    }),
    submit() {
      this.LogIn(this.model)
        .then(() => {
          this.showError = false;
          //console.log("showpick", this.showPick);
        })
        .finally(() => {
          //console.log("showpick final", this.showPick);
        });
    },
    setTeam(id) {
      this.SetTeam({ id: id, refresh: true });
    },
    cancelPick() {
      this.showPick = false;
    },
  },
});
</script>
