<template>
  <div class="flex flex-col sm:flex-row">
    <div
      class="flex-initial pt-1 mr-5 text-indigo-800 dark:text-indigo-300 font-medium text-lg"
    >
      Cari Dosen
    </div>
    <div class="flex-1 input-form">
      <div class="p-fluid">
        <AutoComplete
          v-model="selectedDosen"
          :suggestions="filteredDosen"
          field="label"
          force-selection
          placeholder="Ketikkan Nama Dosen / Kode Dosen / NIDN"
          class="w-auto remake"
          @complete="searchDosen($event)"
        >
          <template #item="slotProps">
            <div class="item">
              <div>
                <strong> {{ slotProps.item?.nama }}</strong>
                {{ slotProps.item?.kode }}
              </div>
            </div>
          </template>
        </AutoComplete>
      </div>
    </div>

    <div class="flex-initial">
      <button
        class="btn btn-default bg-indigo-800 text-white mt-2 sm:ml-2 sm:mt-0"
        :disabled="isDisabled"
        @click="cariDosen"
      >
        <SearchIcon class="h-4" /> Cari
      </button>
    </div>
  </div>
</template>

<script>
import { master } from "@/services/models";
import router from "@/router";
import { userdata } from "@/utils/userdata";

export default {
  name: "CariDosen",
  data() {
    return {
      listDosen: null,
      filteredDosen: null,
      selectedDosen: [],
    };
  },
  computed: {
    isDisabled: function () {
      return !this.selectedDosen?.kode;
    },
  },
  mounted() {
    if (!userdata.isAuthenticated()) return;
    master.Dosen.searchData({}).then((result) => {
      this.listDosen = result?.data?.data?.map((dsn) => {
        return {
          nama: dsn.NM_DOSEN,
          kode: dsn.KODE,
          dsn: dsn,
          nidn: dsn.sdm?.nidn,
          label: dsn.NM_DOSEN + " (" + dsn.KODE + ")",
        };
      });
    });
  },
  methods: {
    searchDosen(event) {
      setTimeout(() => {
        if (!event.query.length) {
          this.filteredDosen = [...this.listDosen];
        } else {
          master.Dosen.searchData({
            params: {
              q: event.query,
            },
          }).then((result) => {
            this.listDosen = result.data.data.map((dsn) => {
              return {
                nama: dsn.NM_DOSEN,
                kode: dsn.KODE,
                dsn: dsn,
                nidn: dsn.sdm?.nidn,
                label: dsn.NM_DOSEN + " (" + dsn.KODE + ")",
              };
            });
            this.filteredDosen = [...this.listDosen];
          });
        }
      }, 100);
    },
    cariDosen() {
      router.push({
        name: "view-dosen",
        params: { id: this.selectedDosen?.kode },
      });
    },
  },
};
</script>

<style>
.remake > input {
  background: white;
  border-radius: 8px;
  box-shadow: none !important;
  padding: 8px;
}
</style>
