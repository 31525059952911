<template>
  <div class="grid grid-cols-4 gap-4">
    <div class="col-span-4">
      <div class="grid grid-cols-2 gap-2">
        <div class="col-span-1 input-form">
          <label class="form-label">Apps</label>
          <TailSelect
            v-model="appSelected"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option
              v-for="(item, index) in apps"
              :key="index"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </TailSelect>
        </div>
      </div>
    </div>
    <div class="col-span-1">
      <FeatureOrMenu :app-id="appSelected" @event="getFeatures" />
    </div>
    <div class="col-span-3">
      <div class="grid grid-cols-1">
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <AlertFailed v-if="alertFailed" :message="messageAlert" />
      </div>
      <h1 class="font-bold text-lg">Management Team Ability</h1>
      <div class="grid grid-cols-2">
        <div class="input-form mt-5">
          <label class="form-label">Team</label>
          <TailSelect
            v-model="teamSelected"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option
              v-for="(item, index) in teams"
              :key="index"
              :value="item.value"
            >
              {{ item.text }}
            </option>
          </TailSelect>
        </div>
        <div v-if="teamSelected" class="input-form mt-5 ml-3">
          <button class="btn btn-primary mt-7" @click="simpan">Simpan</button>
        </div>
      </div>

      <div id="faq-accordion-2" class="accordion accordion-boxed mt-5">
        <div class="accordion-item">
          <div id="faq-accordion-content-1" class="accordion-header">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-5"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-5"
            >
              Apps Features
            </button>
          </div>
          <div
            id="faq-accordion-collapse-5"
            class="accordion-collapse collapse"
            :class="featureAndMenu?.length > 0 ?? 'show'"
            aria-labelledby="faq-accordion-content-1"
            data-bs-parent="#faq-accordion-2"
          >
            <div
              class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
            >
              <div v-if="featureAndMenu?.length > 0">
                <div
                  v-for="(item, index) in featureAndMenu"
                  :key="index"
                  class="grid grid-cols-1 gap-1 mb-1 border rounded p-1"
                >
                  <div class="col-span-1">
                    <div v-if="item.feature == 'config'">
                      <div class="font-bold flex items-center capitalize">
                        <input
                          id="config"
                          v-model="configSelected"
                          class="form-check-input"
                          type="checkbox"
                        />
                        <label class="form-check-label capitalize" for="config">
                          {{ item.feature }}
                        </label>
                      </div>
                    </div>
                    <h1
                      v-if="item.feature != 'config'"
                      class="font-bold capitalize"
                    >
                      {{ item.feature }}
                    </h1>
                  </div>
                  <div v-if="item.feature != 'config'" class="col-span-1">
                    <div class="grid grid-cols-9 gap-1 ml-4">
                      <div
                        v-for="(menu, n) in item.menus"
                        :key="n"
                        class="col-span-1"
                      >
                        <div class="form-check">
                          <input
                            :id="'sw-' + n + '-' + index"
                            v-model="menu.selected"
                            class="form-check-input"
                            type="checkbox"
                            :value="menu.value"
                          />
                          <label
                            class="form-check-label capitalize"
                            :for="'sw-' + n + '-' + index"
                          >
                            {{ menu.menu }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="text-center">
                Apps ini belum memiliki fitur yang terdata
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div id="faq-accordion-content-2" class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-6"
              aria-expanded="false"
              aria-controls="faq-accordion-collapse-6"
            >
              Token Ability
            </button>
          </div>
          <div
            id="faq-accordion-collapse-6"
            class="accordion-collapse collapse"
            aria-labelledby="faq-accordion-content-2"
            data-bs-parent="#faq-accordion-2"
          >
            <div
              class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
            >
              <div class="grid grid-cols-4 mb-2 gap-2">
                <div
                  v-for="(item, index) in abilities?.filter(
                    (f) => f?.is_sub !== true
                  )"
                  :key="index"
                  class="col-span-1 shadow p-3"
                >
                  <div class="form-check mt-2">
                    <input
                      :id="'sw-' + index"
                      v-model="item.selected"
                      class="form-check-input"
                      type="checkbox"
                      :value="item.id"
                    />
                    <label
                      class="font-bold form-check-label capitalize"
                      :for="'sw-' + index"
                    >
                      {{ item.ability }}
                    </label>
                  </div>
                  <div class="mt-2">
                    {{ item.description }}
                  </div>
                  <div
                    v-for="sub in abilities?.filter(
                      (f) => f?.is_sub === true && f?.group === item.ability
                    )"
                    :key="sub.id"
                  >
                    <div class="form-check mt-2">
                      <input
                        :id="'sw-' + sub.id"
                        v-model="sub.selected"
                        class="form-check-input"
                        type="checkbox"
                        :value="sub.id"
                      />
                      <label
                        class="font-bold form-check-label"
                        :for="'sw-' + sub.id"
                      >
                        {{ sub.ability }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeatureOrMenu from "./FeatureOrMenu.vue";
import AlertSuccess from "../../../components/alerts/AlertSuccess.vue";
import AlertFailed from "../../../components/alerts/AlertFailed.vue";
import { master } from "@/services/models";

export default {
  components: {
    FeatureOrMenu,
    AlertSuccess,
    AlertFailed,
  },
  data() {
    return {
      teams: [],
      features: [],
      abilities: [],
      menus: [],
      apps: [],
      featureAndMenu: [],
      teamSelected: null,
      appFeature: true,
      tokenAbility: false,
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",

      configSelected: false,
      appSelected: null,
    };
  },
  watch: {
    teamSelected: function () {
      this.configSelected = false;
      let team = this.teams.find((item) => item.value == this.teamSelected);
      let ta = team?.teamAbility.filter((i) => i.apps_id == this.appSelected);
      if (ta?.length > 0) {
        this.setSelectedByTeam(ta[0]?.app_features, ta[0]?.token_ability);
      } else {
        this.setSelectedByTeam(null);
      }
    },
    appSelected: function () {
      this.getTeams();
      this.getFeatures();
      this.getAbilities();
    },
  },
  mounted() {
    this.getApps();
  },
  methods: {
    async getApps() {
      master.Apps.getAll().then((res) => {
        let tmpApps = [];
        tmpApps.push({
          id: 0,
          value: "",
          text: "Apps",
          selected: true,
        });
        if (res.status === 200) {
          res.data.forEach((element) => {
            tmpApps.push({
              id: element.id,
              value: element.id,
              text: element.nama,
              selected: element.id.toString() === "22",
            });
            if (element.id.toString() === "22")
              this.appSelected = element.id.toString();
          });
          this.apps = tmpApps;
        }
      });
    },
    async getTeams() {
      this.teams = [];
      this.teams.push({
        id: 0,
        value: "",
        text: "Pilih Team",
        selected: true,
        teamAbility: [],
      });
      master.Team.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((item) => {
            this.teams.push({
              id: item.id,
              value: item.id,
              text: item.name,
              selected: false,
              teamAbility: item.team_abilities,
            });
          });
        }
      });
    },
    async getFeatures() {
      this.features = [];
      this.menus = [];
      master.Features.getData(this.appSelected).then((res) => {
        if (res.status === 200) {
          const data = res.data;

          data.forEach((item) => {
            if (item.isMenu == 0) {
              this.features.push({
                id: item.id,
                feature: item.feature,
                description: item.description,
              });
            } else if (item.isMenu == 1) {
              this.menus.push({
                id: item.id,
                menu: item.feature,
                description: item.description,
              });
            }
          });

          this.prosessFeatureAndMenu();
        }
      });
    },
    async getAbilities() {
      this.abilities = [];
      master.Abilities.getData(this.appSelected).then((res) => {
        if (res.status === 200) {
          res.data.forEach((item) => {
            this.abilities.push({
              id: item.id,
              ability: item.ability,
              description: item.description,
              selected: false,
            });

            if (item.sub_ability?.length > 0) {
              item.sub_ability.forEach((it) => {
                this.abilities.push({
                  id: it.id,
                  ability: it.ability,
                  is_sub: true,
                  group: item.ability,
                  description: it.description,
                  selected: false,
                });
              });
            }
          });
        }
      });
    },
    setSelectedByTeam(featureApps, ability) {
      this.featureAndMenu = [];

      if (featureApps) {
        if (featureApps.includes("config")) {
          this.configSelected = true;
        }
      }

      this.features.forEach((item) => {
        let newMenus = this.menus.map((v) => ({
          ...v,
          selected:
            featureApps != null
              ? featureApps[0] == "*"
                ? true
                : featureApps.includes(`${item.feature}:${v.menu}`)
              : false,
          value: `${item.feature}:${v.menu}`,
        }));
        this.featureAndMenu.push({
          ...item,
          menus: newMenus,
        });
      });

      let newAbilities = this.abilities.map((v) => ({
        ...v,
        selected:
          ability != null
            ? ability[0] == "*"
              ? true
              : ability.includes(v.ability)
            : false,
      }));

      this.abilities = [];
      this.abilities = newAbilities;
    },
    prosessFeatureAndMenu() {
      this.featureAndMenu = [];
      this.features.forEach((item) => {
        let newMenus = this.menus.map((v) => ({
          ...v,
          selected: false,
          value: `${item.feature}:${v.menu}`,
        }));
        this.featureAndMenu.push({
          ...item,
          menus: newMenus,
        });
      });
    },
    openClose(val) {
      if (val == "feature") {
        this.appFeature = true;
        this.tokenAbility = false;
      } else if (val == "token") {
        this.appFeature = false;
        this.tokenAbility = true;
      }
    },
    simpan() {
      this.alertSucces = false;
      this.alertFailed = false;
      let active = [];
      let abilitiesActive = [];

      if (this.configSelected) {
        active.push("config");
      }

      this.featureAndMenu.forEach((item) => {
        let featuremenu = item.menus.filter((key) => key.selected == true);
        featuremenu.forEach((menu) => {
          active.push(menu.value);
        });
      });

      let abilitiesselected = this.abilities.filter(
        (key) => key.selected == true
      );

      abilitiesselected.forEach((e) => {
        abilitiesActive.push(e.ability);
      });

      let data = {
        team_id: this.teamSelected,
        apps_id: this.appSelected,
        apps_features: JSON.stringify(active).replace(/\\/g, ""),
        token_ability: JSON.stringify(abilitiesActive).replace(/\\/g, ""),
      };

      master.TeamAbility.insertData(data).then((res) => {
        if (res.status === 200) {
          this.alertSuccess = true;
          this.messageAlert = res.data.message;
          this.getTeams();
        }
      });
    },
  },
};
</script>
<style scoped>
.capitalize:first-letter {
  text-transform: capitalize;
}
</style>
