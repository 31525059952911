<template>
  <div>
    <form>
      <div class="mt-3">
        <label class="form-label">Nama Server</label>
        <input type="text" class="form-control" placeholder="Nama Server" />
      </div>
      <div class="mt-3">
        <label class="form-label">Url Server</label>
        <input
          type="url"
          class="form-control"
          placeholder="Alamat Url Server (http://localhost:8082)"
        />
      </div>
      <div class="mt-3">
        <label class="form-label">Username</label>
        <input
          type="text"
          class="form-control"
          placeholder="Username Operator PT"
        />
      </div>
      <div class="mt-3">
        <label class="form-label">Password</label>
        <input
          type="password"
          class="form-control"
          placeholder="Password Operator PT"
        />
      </div>
      <button class="btn btn-primary mt-5">Simpan</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "FormAturServerFeeder",
};
</script>

<style scoped></style>
