<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="intro-y md:flex items-center mt-10">
          <h2 class="text-lg font-medium truncate">
            Dashboard {{ $u.level.name }}
          </h2>

          <!--          <div class="w-full md:ml-auto md:w-auto md:flex md:mt-3 mt-4 md:mt-0">
            <router-link
              v-if="$u.isKepalaUnit() || $u.isSuperAdmin()"
              :to="{ name: 'config' }"
              class="btn btn-default mb-3 md:ml-2 md:mt-0 mt-2"
            >
              <ToolIcon class="w-4 mr-2" />
              Pengaturan
            </router-link>
          </div>-->
        </div>
        <AlertFailed
          :message="responseMessage"
          :dismissable="true"
          @closeAndClear="closeAndClear"
        ></AlertFailed>
        <div class="intro-y box mt-5 px-6 py-6">
          <!--          <LayoutCari v-if="hasMainFeatures('search')"></LayoutCari>-->
          <LayoutBAK v-if="$u.isKepalaUnit() || $u.isSuperAdmin()"></LayoutBAK>
          <LayoutWR2 v-else-if="$u.isWR2()"></LayoutWR2>
          <LayoutAdm v-else-if="$u.isAdminStaff()"></LayoutAdm>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LayoutCari from "@/views/dashboard-mdp/layout-bak/LayoutCari";
import LayoutAdm from "@/views/dashboard-mdp/layout-adm/Main";
import LayoutBAK from "@/views/dashboard-mdp/layout-bak/Main";
import LayoutWR2 from "@/views/dashboard-mdp/layout-wr2/Main";
import AlertFailed from "@/components/alerts/AlertFailed";
import store from "@/store";
export default {
  components: {
    AlertFailed,
    LayoutCari,
    LayoutBAK,
    LayoutWR2,
    LayoutAdm,
  },
  computed: {
    responseMessage() {
      return this.$store.state.main.responseMessage;
    },
  },
  watch: {
    responseMessage: {
      handler: function () {},
      immediate: true,
    },
  },
  mounted() {
    console.log(store.getters["auth/abilities"]);
  },
  methods: {
    closeAndClear() {
      store.commit("main/clearResponse");
    },
  },
};
</script>
