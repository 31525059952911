import axios from "axios";
import axiosRetry from "axios-retry";
const HTTP = axios.create({
  baseURL: "https://api.mdp.ac.id/feederapi/",
  headers: {
    "Content-Type": "application/json",
  },
});

HTTP.interceptors.request.use((req) => {
  return req;
});

axiosRetry(HTTP, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isRetryableError,
});

export { HTTP };
