<template>
  <div>
    <div class="grid grid-cols-1 gap-3">
      <router-link
        :to="{ name: 'nota-bernomor' }"
        class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
      >
        <div>
          <FileTextIcon class="text-theme-1 dark:text-white block w-6 h-6" />
        </div>
        <div>Buat Bukti Transaksi Penerimaan (Nota)</div>
      </router-link>
    </div>
    <div class="grid grid-cols-1 gap-3">
      <router-link
        :to="{ name: 'rekap-nota-bernomor' }"
        class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
      >
        <div>
          <FileTextIcon class="text-theme-1 dark:text-white block w-6 h-6" />
        </div>
        <div>Rekap Nota</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import GraduationCap from "../../../assets/icons/graduationCap";
import Flag from "../../../assets/icons/flag";
import BallotCheck from "../../../assets/icons/ballotCheck";
export default {
  name: "LayoutMenuKeuanganMhs",
  components: { GraduationCap, Flag, BallotCheck },
};
</script>

<style scoped></style>
