<template>
  <div class="grid grid-cols-2 gap-2">
    <div class="col-span-2">
      <div class="grid grid-cols-2 gap-2">
        <div class="col-span-1 sinput-form mb-5">
          <label class="form-label">Apps</label>
          <TailSelect
            v-model="appSelected"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false
            }"
          >
            <option
              v-for="(item, index) in apps"
              :key="index"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </TailSelect>
        </div>
        <!--        <div class="col-span-1">
          <button class="btn btn-primary" @click="submit">Simpan</button>
        </div>-->
      </div>
    </div>
    <div class="col-span-1 mr-5">
      <form class="inline-flex gap-2" @submit.prevent="addNewAbility">
        <div class="input-form">
          <label class="form-label">Tambah Ability</label>
          <input
            v-model="newAbility"
            required
            class="form-control"
            autocomplete="off"
            placeholder="Ability Baru"
          />
        </div>
        <div class="input-form">
          <label class="form-label">Description</label>
          <input
            v-model="description"
            class="form-control"
            autocomplete="off"
            placeholder="Description"
            required
          />
        </div>
        <div class="mt-7">
          <button class="btn btn-primary" type="submit">Tambah</button>
        </div>
      </form>
    </div>
    <div class="col-span-1">
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />

      <div class="grid grid-cols-2 gap-2">
        <div class="col-span-1 sinput-form mb-5">
          <label class="form-label">User</label>
          <TailSelect
            v-model="userSelected"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false
            }"
          >
            <option
              v-for="(item, index) in users"
              :key="index"
              :value="item.value"
            >
              {{ item.text }}
            </option>
          </TailSelect>
        </div>
        <div v-if="abilities.length > 0" class="col-span-1">
          <div v-if="userSelected">
            <div class="form-check mb-2">
              <input
                id="all"
                v-model="abSelected"
                class="form-check-input"
                type="checkbox"
                value="*"
              />
              <label class="form-check-label" for="all"> Semua </label>
            </div>
            <div v-if="userSelected">
              <button class="btn btn-primary" @click="submit">Simpan</button>
              <button class="btn btn-danger ml-2" @click="remove">Hapus</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <div
        v-for="(item, index) in abilities"
        :key="index"
        class="grid grid-cols-12 gap-1 mb-1"
      >
        <div class="col-span-12 border rounded p-2">
          <div class="grid grid-cols-2">
            <div class="col-span-1">
              <div class="gap-1 mb-1">
                <div class="inline-flex">
                  <div class="font-bold">
                    {{ item.ability.toUpperCase() }}
                  </div>
                  <div class="font-normal text-small ml-2">
                    {{ item.description }}
                  </div>
                </div>
              </div>
              <div v-if="item.has_access == 1" class="inline-flex gap-1">
                <div
                  v-for="(can, n) in item.canAccess"
                  :key="n"
                  class="col-span-2 ml-10"
                >
                  <div class="form-check">
                    <input
                      :id="'checkbox-switch-' + n + '-' + index"
                      v-model="can.selected"
                      class="form-check-input"
                      type="checkbox"
                      :value="can.value"
                    />
                    <label
                      class="form-check-label"
                      :for="'checkbox-switch-' + n + '-' + index"
                    >
                      {{ can.access }}
                    </label>
                  </div>
                </div>
              </div>
              <div v-else class="inline-flex gap-1">
                <div class="col-span-2 ml-10">
                  <div class="form-check">
                    <input
                      :id="'checkbox-switch-' + item.ability"
                      v-model="item.canAccess[0].selected"
                      class="form-check-input"
                      type="checkbox"
                      :value="item.canAccess[0].value"
                    />
                    <label
                      class="form-check-label"
                      :for="'checkbox-switch-' + item.ability"
                    >
                      {{ item.ability }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { master } from "@/services/models";
import AlertSuccess from "../../../components/alerts/AlertSuccess.vue";
export default {
  components: {
    AlertSuccess
  },
  data() {
    return {
      abilities: [],
      users: [],
      apps: [],
      canAccess: ["list", "view", "create", "update", "delete"],
      abSelected: null,
      all: false,
      allIsSelect: false,
      userSelected: null,
      appSelected: null,
      alertSuccess: false,
      messageAlert: "",
      newAbility: null,
      description: null,
      has_access: null,
      abilityGroup: null
    };
  },
  watch: {
    abSelected: function() {
      this.all = !this.all;
      if (this.abSelected != "not") {
        this.allIsSelect = !this.allIsSelect;
        this.setAllSelected();
      }
    },
    userSelected: function() {
      let dataSelected = this.users.filter(
        item => item.id == this.userSelected
      );
      this.setActiveAbilityByUserSelected(dataSelected[0]["ability"]);
    },
    appSelected: function() {
      this.getAbilities();
      this.getListUserAbilities();
    }
  },
  mounted() {
    this.getApps();
  },
  methods: {
    async getApps() {
      master.Apps.getAll().then(res => {
        let tmpApps = [];
        tmpApps.push({
          id: 0,
          value: "",
          text: "Apps",
          selected: true
        });
        if (res.status === 200) {
          res.data.forEach(element => {
            tmpApps.push({
              id: element.id,
              value: element.id,
              text: element.nama,
              selected: element.id.toString() === "22"
            });
            if (element.id.toString() === "22") this.appSelected = element.id;
          });
          this.apps = tmpApps;
        }
      });
    },
    async getAbilities() {
      master.Abilities.getData(this.appSelected).then(res => {
        let tmpAbility = [];
        if (res.status == 200) {
          let n = 1;
          res.data.forEach(element => {
            let canAccessTmp = [];
            if (element.has_access == "1") {
              this.canAccess.forEach(item => {
                canAccessTmp.push({
                  id: n,
                  access: item,
                  value: element.ability + ":" + item,
                  selected: false
                });
                n++;
              });
            } else {
              canAccessTmp.push({
                id: n,
                access: element.ability,
                value: element.ability,
                selected: false
              });
              n++;
            }
            if (element.sub_ability?.length > 0) {
              element.sub_ability.forEach(item => {
                canAccessTmp.push({
                  id: n,
                  group: element.ability,
                  access: item.ability,
                  value: item.ability,
                  selected: false
                });
                n++;
              });
            }

            tmpAbility.push({
              ...element,
              canAccess: canAccessTmp
            });
          });
          this.abilities = tmpAbility;
        }
      });
    },
    async getListUserAbilities() {
      this.users = [];
      this.users.push({
        id: 0,
        value: "",
        text: "User",
        selected: true,
        ability: []
      });
      master.ListUserAbilities.getData(this.appSelected).then(res => {
        if (res.status === 200) {
          res.data.data.forEach(item => {
            this.users.push({
              id: item.user_id,
              value: item.user_id,
              userAbilitiesId: item.id,
              text: item.user?.uid + " | " + item.user?.name,
              selected: false,
              ability: item.token_ability
            });
          });
        }
      });
    },
    setAllSelected() {
      let tmp = [];
      this.abilities.forEach(item => {
        let canAccessTmp = [];
        item.canAccess.forEach(item => {
          canAccessTmp.push({
            ...item,
            selected: this.allIsSelect
          });
        });

        tmp.push({
          ...item,
          canAccess: canAccessTmp
        });
      });
      this.abilities = tmp;
    },
    setActiveAbilityByUserSelected(abilitiesUser) {
      if (abilitiesUser[0] != "*") {
        this.abSelected = "not";
        this.allIsSelect = false;
        let tmp = [];
        this.abilities.forEach(itemAb => {
          let canAccessTmp = [];
          let active = [];
          itemAb.canAccess.forEach(item => {
            abilitiesUser.forEach(abUser => {
              if (abUser == item.value) {
                active.push(item);
              }
            });
          });
          itemAb.canAccess.forEach(item => {
            let onOff = active.filter(e => e.id == item?.id);
            canAccessTmp.push({
              ...item,
              selected: onOff.length > 0 ? true : false
            });
          });

          tmp.push({
            ...itemAb,
            canAccess: canAccessTmp
          });
        });
        this.abilities = tmp;
      } else {
        this.abSelected = true;
      }
    },
    submit() {
      this.alertSuccess = false;
      let abilitiesTrue = [];
      this.abilities.forEach(item => {
        let access = item.canAccess.filter(access => access.selected == true);
        access.forEach(abilities => {
          abilitiesTrue.push(abilities?.value);
        });
      });
      let abilitiesSelect = JSON.stringify(["*"]).replace(/\\/g, "");
      if (!this.allIsSelect) {
        abilitiesSelect = JSON.stringify(abilitiesTrue).replace(/\\/g, "");
      }
      let data = {
        user_id: this.userSelected,
        apps_id: this.appSelected,
        token_ability: abilitiesSelect
      };
      master.UserAbility.postCustomPath("store", data)
        .then(res => {
          if (res.status == 200) {
            this.getAbilities();
            this.getListUserAbilities();
            this.alertSuccess = true;
            this.messageAlert = "Ability Berhasil di Tambahkan";
          }
        })
        .finally(() => {
          let dataSelected = this.users.filter(
            item => item.id == this.userSelected
          );
          this.setActiveAbilityByUserSelected(dataSelected[0]["ability"]);
        });
    },
    remove() {
      this.alertSuccess = false;
      let dataSelected = this.users.filter(
        item => item.id == this.userSelected
      );
      master.UserAbility.deleteData(dataSelected[0]["userAbilitiesId"]).then(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.alertSuccess = true;
            this.messageAlert = "Data Berhasil di Hapus";
            this.abilities = [];
            this.users = [];
            this.canAccess = ["list", "view", "create", "update", "delete"];
            this.abSelected = null;
            this.all = false;
            this.newAbility = null;
            this.description = null;
            this.getAbilities();
            this.getListUserAbilities();
          }
        }
      );
    },
    addNewAbility() {
      let data = {
        ability: this.newAbility,
        description: this.description,
        apps_id: this.appSelected,
        has_access: this.has_access,
        group: this.abilityGroup
      };
      master.Abilities.postCustomPath("storeAbilities", data).then(res => {
        if (res.status === 200) {
          this.getAbilities();
          this.getListUserAbilities();
          this.newAbility = null;
          this.description = null;
        }
      });
    }
  }
};
</script>

<style scoped>
.capitalize:first-letter {
  text-transform: capitalize;
}
</style>
