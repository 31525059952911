<template>
  <div class="grid md:grid-cols-2 xl:grid-cols-4 sm:grid-cols-1 gap-3 bg-blue-100 p-3">
    <router-link
      class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200 bg-gray-50"
      :to="{ name: 'tagihan-validasi' }"
    >
      <div>
        <UserCheck class="text-theme-1 dark:text-white block w-6 h-6 mx-auto" />
      </div>
      <div>Validasi Transaksi Pembayaran</div>
    </router-link>

    <router-link
      class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200 bg-gray-50"
      :to="{ name: 'validasi-tagihan-permhs' }"
    >
      <div>
        <UserCheck class="text-theme-1 dark:text-white block w-6 h-6 mx-auto" />
      </div>
      <div>Validasi Transaksi Per Mahasiswa</div>
    </router-link>

    <router-link
      class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200 bg-gray-50"
      :to="{ name: 'validasi-transaksi-pmb' }"
    >
      <div>
        <CalendarCheck class="text-theme-1 dark:text-white block w-6 h-6 mx-auto" />
      </div>
      <div>Validasi Pembayaran Mahasiswa Baru</div>
    </router-link>

    <router-link
      class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200 bg-gray-50"
      :to="{ name: 'validasi-transaksi-uk-cicilan' }"
    >
      <div>
        <CalendarCheck class="text-theme-1 dark:text-white block w-6 h-6 mx-auto" />
      </div>
      <div>Validasi Pembayaran Cicilan UK</div>
    </router-link>
  </div>
</template>

<script>
import BallotCheck from "../../../assets/icons/ballotCheck";
import CalendarCheck from "../../../assets/icons/calendarCheck";
import UserCheck from "../../../assets/icons/userCheck";
import CalendarRange from "../../../assets/icons/calendarRange";

export default {
  name: "LayoutMenuValidasi",
  components: { BallotCheck, CalendarCheck, UserCheck, CalendarRange },
};
</script>

<style scoped>

</style>
