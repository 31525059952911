<template>
  <div class="intro-y flex flex-col lg:flex-row mb-3 pb-3 border-b">
    <div v-if="hasFeatures('search:mahasiswa')" class="flex-1 md:mr-5">
      <CariMahasiswa />
    </div>
    <div
      v-if="hasFeatures('search:dosen')"
      class="flex-1 lg:pl-5 lg:border-l mt-3 lg:mt-0"
    >
      <CariDosen />
    </div>
  </div>
</template>

<script>
import CariMahasiswa from "@/components/form/CariMahasiswa";
import CariDosen from "@/components/form/CariDosen";

export default {
  name: "LayoutCari",
  components: { CariDosen, CariMahasiswa },
};
</script>

<style scoped></style>
