<template>
  <div>
    <!-- BEGIN: Pricing Tab -->
    <div class="intro-y flex justify-center">
      <div
        class="pricing-tabs nav nav-tabs box dark:bg-dark-1 rounded-full overflow-hidden"
        role="tablist"
      >
        <a
          id="layout-perkuliahan-tab"
          data-toggle="tab"
          data-target="#layout-perkuliahan"
          href="javascript:;"
          class="flex-1 w-32 lg:w-40 py-2 lg:py-3 whitespace-nowrap text-center active"
          role="tab"
          aria-controls="layout-perkuliahan"
          aria-selected="true"
          >Perkuliahan</a
        >
        <a
          id="layout-akademik-tab"
          data-toggle="tab"
          data-target="#layout-akademik"
          href="javascript:;"
          class="flex-1 w-32 lg:w-40 py-2 lg:py-3 whitespace-nowrap text-center"
          role="tab"
          aria-controls="layout-akademik"
          aria-selected="false"
          >Akademik</a
        >
        <a
          id="layout-litabmas-tab"
          data-toggle="tab"
          data-target="#layout-litabmas"
          href="javascript:;"
          class="flex-1 w-32 lg:w-40 py-2 lg:py-3 whitespace-nowrap text-center"
          role="tab"
          aria-controls="layout-litabmas"
          aria-selected="false"
          >LITABMAS</a
        >
      </div>
    </div>
    <!-- END: Pricing Tab -->
    <!-- BEGIN: Pricing Content -->
    <div class="flex mt-10">
      <div class="tab-content">
        <div
          id="layout-perkuliahan"
          class="tab-pane flex flex-col lg:flex-row active"
          role="tabpanel"
          aria-labelledby="layout-perkuliahan-tab"
        >
          <!--          <LayoutMenuPerkuliahan></LayoutMenuPerkuliahan>-->
        </div>
        <div
          id="layout-akademik"
          class="tab-pane flex flex-col lg:flex-row"
          role="tabpanel"
          aria-labelledby="layout-akademik-tab"
        ></div>
        <div
          id="layout-litabmas"
          class="tab-pane flex flex-col lg:flex-row"
          role="tabpanel"
          aria-labelledby="layout-litabmas-tab"
        >
          <!--          <LayoutMenuLitabmas></LayoutMenuLitabmas>-->
        </div>
      </div>
    </div>
    <!-- END: Pricing Content -->
  </div>
</template>

<script>
//import LayoutMenuPerkuliahan from "@/views/dashboard-mdp/layout-wr1/LayoutMenuPerkuliahan";
//import LayoutMenuLitabmas from "@/views/dashboard-mdp/layout-wr1/LayoutMenuLitabmas";
export default {
  name: "Main",
};
</script>

<style scoped></style>
