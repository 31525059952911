<template>
  <div class="grid grid-cols-1 gap-3">
    <router-link
      class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
      :to="{ name: 'tagihan-list' }"
    >
      <div>
        <CalendarRange
          class="text-theme-1 dark:text-white block w-6 h-6 mx-auto"
        />
      </div>
      <div>Tagihan dan Transaksi Pembayaran</div>
    </router-link>

    <router-link
      class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
      :to="{ name: 'validasi-tagihan-permhs' }"
    >
      <div>
        <UserCheck class="text-theme-1 dark:text-white block w-6 h-6 mx-auto" />
      </div>
      <div>Tagihan dan Transaksi per Mahasiswa</div>
    </router-link>
  </div>
</template>

<script>
import CalendarRange from "../../../assets/icons/calendarRange";
import UserCheck from "../../../assets/icons/userCheck";
export default {
  name: "LayoutMenuBiayaKuliah",
  components: { UserCheck, CalendarRange },
};
</script>

<style scoped></style>
