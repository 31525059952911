<template>
  <div class="grid grid-cols-1 gap-4">
    <div class="col-span-1">
      <div class="grid grid-cols-1">
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <AlertFailed v-if="alertFailed" :message="messageAlert" />
        <div v-if="alertFailedStack">
          <AlertFailed
            v-for="(item, index) in messageAlertStack"
            :key="index"
            :message="item"
          />
        </div>
      </div>
      <h1 class="font-bold text-lg">Tambah Feature atau Menu</h1>
      <div class="input-form mt-5">
        <label class="form-label">Nama Team</label>
        <input
          v-model="feature"
          class="form-control"
          autocomplete="off"
          placeholder="Feature atau Menu"
        />
      </div>
      <div class="input-form mt-5">
        <label class="form-label">Description</label>
        <input
          v-model="description"
          class="form-control"
          autocomplete="off"
          placeholder="Description"
        />
      </div>
      <div class="input-form mt-4">
        <div class="form-label">Jenis</div>
        <div>
          <div class="form-check mb-2">
            <input
              id="feature"
              v-model="jenis"
              class="form-check-input"
              type="radio"
              name="jenis"
              value="Feature"
            />
            <label class="form-check-label" for="feature">Feature</label>
          </div>
          <div class="form-check">
            <input
              id="menu"
              v-model="jenis"
              class="form-check-input"
              type="radio"
              name="jenis"
              value="Menu"
            />
            <label class="form-check-label" for="menu">Menu</label>
          </div>
        </div>
      </div>
      <div class="input-form mt-4">
        <button class="btn btn-primary" @click="storeFeatureOrMenu">
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AlertSuccess from "../../../components/alerts/AlertSuccess.vue";
import AlertFailed from "../../../components/alerts/AlertFailed.vue";
import { master } from "../../../services/models/master";
export default {
  components: {
    AlertSuccess,
    AlertFailed,
  },
  props: {
    appId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      alertSuccess: false,
      alertFailed: false,
      alertFailedStack: false,
      messageAlert: "",
      messageAlertStack: [],

      feature: null,
      description: null,
      jenis: null,
    };
  },
  methods: {
    storeFeatureOrMenu() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.alertFailedStack = false;
      this.messageAlertStack = [];

      let data = {
        feature: this.feature,
        description: this.description,
        jenis: this.jenis,
        apps_id: this.appId,
      };

      master.Features.insertData(data).then((res) => {
        if (res?.status === 200) {
          if (res.data.code === 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;
            this.$emit("event", true);
          } else {
            this.alertFailedStack = true;
            for (const key in res.data.message) {
              this.messageAlertStack.push(res.data.message[key][0]);
            }
          }
        }
      });
    },
  },
};
</script>
