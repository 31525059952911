<template>
  <div>
    <LayoutMenuValidasi class="mb-4"></LayoutMenuValidasi>
    <div class="grid md:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 gap-3">
      <div class="intro-y box shadow rounded-lg">
        <h1 class="border-b p-5 text-lg font-bold">Biaya Kuliah</h1>
        <div class="p-3">
          <LayoutMenuBiayaKuliah></LayoutMenuBiayaKuliah>
        </div>
      </div>
      <div class="intro-y box shadow rounded-lg">
        <h1 class="border-b p-5 text-lg font-bold">Keuangan Mahasiswa</h1>
        <div class="p-3">
          <LayoutMenuKeuanganMhs></LayoutMenuKeuanganMhs>
        </div>
      </div>
      <div class="intro-y box shadow rounded-lg">
        <h1 class="border-b p-5 text-lg font-bold">Keuangan Dosen dan Karyawan</h1>
        <div class="p-3">
          <LayoutMenuPTK></LayoutMenuPTK>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutMenuKeuanganMhs from "@/views/dashboard-mdp/layout-bak/LayoutMenuKeuanganMhs";
import LayoutMenuBiayaKuliah from "@/views/dashboard-mdp/layout-bak/LayoutMenuBiayaKuliah";
import LayoutMenuPTK from "@/views/dashboard-mdp/layout-bak/LayoutMenuPTK";
import LayoutMenuValidasi from "./LayoutMenuValidasi";
export default {
  name: "Main",
  components: { LayoutMenuValidasi, LayoutMenuBiayaKuliah, LayoutMenuKeuanganMhs, LayoutMenuPTK },
};
</script>

<style scoped></style>
