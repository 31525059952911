<template>
  <div class="grid grid-cols-3 gap-4">
    <div class="span-cols-1">
      <div class="intro-x flex items-center mb-5">
        <h2 class="text-md font-medium truncate">Pengaturan Penilaian Ujian</h2>
      </div>
      <div class="mt-3">
        <label class="form-label">Input Nilai UTS</label>
        <div class="form-check mt-2">
          <div class="form-check">
            <input
              id="input_uts"
              v-model="konfig.input_uts"
              class="form-check-switch"
              type="checkbox"
            />
            <label class="form-check-label" for="input_uts">Buka</label>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <label class="form-label">Input Nilai Akhir</label>
        <div class="form-check">
          <input
            id="input_uas"
            v-model="konfig.input_uas"
            class="form-check-switch"
            type="checkbox"
          />
          <label class="form-check-label" for="input_uas">Buka</label>
        </div>
      </div>

      <button class="btn btn-primary mt-5" @click="simpanConfigUjian">
        <SaveIcon class="w-4 h-4 mr-1"></SaveIcon>
        Simpan
      </button>
      <div
        v-if="statusConfUjian"
        class="mt-2"
        :class="statusConfUjian.status ? 'text-theme-9' : 'text-theme-12'"
      >
        {{ statusConfUjian.message }}
      </div>
    </div>

    <div class="span-cols-1">
      <div class="intro-x flex items-center mb-5">
        <h2 class="text-md font-medium truncate">Pengaturan Sidang</h2>
      </div>
      <div class="grid grid-cols-2">
        <div class="span-cols-1 mr-3">
          <div class="">
            <label class="form-label">Tanggal Sidang Skripsi</label>
            <input
              v-model="konfig.tgl_sidang_skripsi"
              type="date"
              class="form-control form-control-sm"
            />
          </div>

          <div class="mt-3">
            <label class="form-label">Tanggal Sidang KP</label>
            <input
              v-model="konfig.tgl_sidang_kp"
              type="date"
              class="form-control form-control-sm"
            />
          </div>
          <div class="mt-3">
            <label class="form-label">Batas Input Nilai Pembimbing</label>
            <input
              v-model="konfig.tgl_batas_input_pembimbing_kp"
              type="date"
              class="form-control form-control-sm"
            />
          </div>
        </div>
        <div class="span-cols-1">
          <div class="">
            <label class="form-label">Input Nilai Penguji</label>
            <div class="form-check">
              <input
                id="input_penguji"
                v-model="konfig.input_penguji"
                class="form-check-switch"
                type="checkbox"
              />
              <label class="form-check-label" for="input_penguji">Buka</label>
            </div>
          </div>
          <div class="mt-5">
            <label class="form-label">Input Nilai Pembimbing</label>
            <div class="form-check">
              <input
                id="input_pembimbing"
                v-model="konfig.input_pembimbing"
                class="form-check-switch"
                type="checkbox"
              />
              <label class="form-check-label" for="input_pembimbing"
                >Buka</label
              >
            </div>
          </div>
          <button class="btn btn-success mt-11" @click="simpanConfigSidang">
            <SaveIcon class="w-4 h-4 mr-1"></SaveIcon>
            Simpan
          </button>
        </div>
      </div>

      <div
        v-if="statusConfSidang"
        class="mt-2"
        :class="statusConfSidang.status ? 'text-theme-9' : 'text-theme-12'"
      >
        {{ statusConfSidang.message }}
      </div>
    </div>

    <div class="span-cols-1">
      <div class="intro-x flex items-center mb-5">
        <h2 class="text-md font-medium truncate">Pengaturan Bimbingan</h2>
      </div>
      <div class="grid grid-cols-2">
        <div class="span-cols-1 mr-2">
          <div class="">
            <label class="form-label">Tanggal Batas Bimbingan Skripsi</label>
            <input
              v-model="konfig.tgl_batas_bimbingan_skripsi"
              type="date"
              class="form-control form-control-sm"
            />
          </div>
          <div class="mt-6">
            <label class="form-label">Tanggal Batas Bimbingan KP</label>
            <input
              v-model="konfig.tgl_batas_bimbingan_kp"
              type="date"
              class="form-control form-control-sm"
            />
          </div>
          <button class="btn btn-success mt-3" @click="simpanConfigBimbingan">
            <SaveIcon class="w-4 h-4 mr-1"></SaveIcon>
            Simpan
          </button>
        </div>

        <div class="span-cols-1">
          <div class="">
            <label class="form-label">Minimal Bimbingan Skripsi</label>
            <div class="grid grid-cols-2">
              <div class="span-cols-1 mr-2">
                <div class="input-group input-group-sm">
                  <div id="min-bim-s1" class="input-group-text">S1</div>
                  <input
                    v-model="konfig.min_bimbingan_skripsi"
                    type="text"
                    class="form-control form-control-sm"
                    aria-describedby="min-bim-s1"
                  />
                </div>
              </div>
              <div class="span-cols-1">
                <div class="input-group input-group-sm">
                  <div id="min-bim-d3" class="input-group-text">D3</div>
                  <input
                    v-model="konfig.min_bimbingan_skripsi_d3"
                    type="text"
                    class="form-control form-control-sm"
                    aria-describedby="min-bim-d3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Minimal Bimbingan KP</label>
            <div class="grid grid-cols-2">
              <div class="span-cols-1 mr-2">
                <div class="input-group input-group-sm">
                  <div id="min-bim-kp-s1" class="input-group-text">S1</div>
                  <input
                    v-model="konfig.min_bimbingan_kp"
                    type="text"
                    class="form-control form-control-sm"
                    aria-describedby="min-bim-kp-s1"
                  />
                </div>
              </div>
              <div class="span-cols-1">
                <div class="input-group input-group-sm">
                  <div id="min-bim-kp-d3" class="input-group-text">D3</div>
                  <input
                    v-model="konfig.min_bimbingan_kp_d3"
                    type="text"
                    class="form-control form-control-sm"
                    aria-describedby="min-bim-kp-d3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="statusConfBimbingan"
        class="mt-2"
        :class="statusConfBimbingan.status ? 'text-theme-9' : 'text-theme-12'"
      >
        {{ statusConfBimbingan.message }}
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-4">
    <div class="span-cols-1">
      <div class="intro-x flex items-center mb-5">
        <h2 class="text-md font-medium truncate">
          Pengaturan Rilis Jadwal dan Nilai
        </h2>
      </div>

      <div class="grid grid-cols-2 gap-1">
        <div class="span-cols-1">
          <div class="">
            <span class="form-label">Semester</span>
            <select v-model="konfig.rilis_semester" class="form-select sm:mr-2">
              <option
                v-for="smtr in semester"
                :key="smtr.id"
                :value="smtr.value"
              >
                {{ smtr.text }}
              </option>
            </select>
          </div>
          <div class="mt-3">
            <label class="form-label">Rilis Jadwal Mengajar Dosen</label>
            <div class="form-check">
              <input
                id="rilis_jadwal_dosen"
                v-model="konfig.rilis_jadwal_dosen"
                class="form-check-switch"
                type="checkbox"
              />
              <label class="form-check-label" for="rilis_jadwal_dosen"
                >Rilis</label
              >
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Rilis Jadwal Kuliah Mahasiswa</label>
            <div class="form-check">
              <input
                id="rilis_jadwal_kuliah"
                v-model="konfig.rilis_jadwal_kuliah"
                class="form-check-switch"
                type="checkbox"
              />
              <label class="form-check-label" for="rilis_jadwal_kuliah"
                >Rilis</label
              >
            </div>
          </div>

          <div class="mt-3">
            <button class="btn btn-primary" @click="simpanConfigRilis">
              <SaveIcon class="w-4 h-4 mr-1"></SaveIcon>
              Simpan
            </button>
          </div>
        </div>
        <div class="span-cols-1">
          <div class="mt-16">&nbsp;</div>
          <div class="mt-1">
            <label class="form-label">Rilis Nilai UTS</label>
            <div class="form-check mt-2">
              <div class="form-check">
                <input
                  id="rilis_uts"
                  v-model="konfig.rilis_uts"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="rilis_uts">Rilis</label>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Rilis Nilai UAS</label>
            <div class="form-check">
              <input
                id="rilis_uas"
                v-model="konfig.rilis_uas"
                class="form-check-switch"
                type="checkbox"
              />
              <label class="form-check-label" for="rilis_uas">Rilis</label>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="statusConfRilis"
        class="mt-2"
        :class="statusConfRilis.status ? 'text-theme-9' : 'text-theme-12'"
      >
        {{ statusConfRilis.message }}
      </div>
    </div>
  </div>
</template>

<script>
import { master } from "@/services/models";

export default {
  name: "FormAturPenilaian",
  data() {
    return {
      semester: [],
      smt: { a_periode_aktif: 0 },
      konfig: {
        masa_ujian: null,
        input_uts: false,
        input_uas: false,
        input_penguji: null,
        input_pembimbing: null,
        tgl_sidang_skripsi: null,
        tgl_sidang_kp: null,
        tgl_batas_input_pembimbing_kp: null,
        tgl_batas_bimbingan_kp: null,
        tgl_batas_bimbingan_skripsi: null,
        min_bimbingan_skripsi: null,
        min_bimbingan_skripsi_d3: null,
        min_bimbingan_kp: null,
        min_bimbingan_kp_d3: null,
        rilis_semester: 0,
        rilis_uts: null,
        rilis_uas: null,
        rilis_jadwal_kuliah: null,
        rilis_jadwal_dosen: null,
      },
      statusConfUjian: {},
      statusConfSidang: {},
      statusConfBimbingan: {},
      statusConfRilis: {},
    };
  },
  watch: {
    "konfig.rilis_semester": {
      handler: function (v) {
        if (v.toString() !== "0") {
          this.konfig.rilis_uts = this.konfig["rilis_uts_" + v];
          this.konfig.rilis_uas = this.konfig["rilis_uas_" + v];
          this.konfig.rilis_jadwal_kuliah =
            this.konfig["rilis_jadwal_kuliah_" + v];
          this.konfig.rilis_jadwal_dosen =
            this.konfig["rilis_jadwal_dosen_" + v];
        }
      },
    },
  },
  mounted() {
    this.fetchSemester();
  },
  beforeMount() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      let con = [
        "masa_ujian",
        "input_uts",
        "input_uas",
        "input_penguji",
        "input_pembimbing",
        "tgl_sidang_skripsi",
        "tgl_sidang_kp",
        "tgl_batas_input_pembimbing_kp",
        "tgl_batas_bimbingan_kp",
        "tgl_batas_bimbingan_skripsi",
        "min_bimbingan_skripsi",
        "min_bimbingan_kp",
        "min_bimbingan_skripsi_d3",
        "min_bimbingan_kp_d3",
        "rilis_semester",
      ];
      master.Konfigurasi.getAll().then((result) => {
        if (result.status === 200) {
          let konf = result.data.filter((a) => {
            return con.includes(a.nama);
          });
          konf.forEach((a) => {
            if (a.value == "1") this.konfig[a.nama] = true;
            else if (a.value == "0") this.konfig[a.nama] = false;
            else this.konfig[a.nama] = a.value;
          });

          //konf rilis
          let konfr = result.data.filter((a) => {
            return a.nama.includes("rilis");
          });

          konfr.forEach((a) => {
            if (a.value == "1") this.konfig[a.nama] = true;
            else if (a.value == "0") this.konfig[a.nama] = false;
            else this.konfig[a.nama] = a.value;
          });
        }
      });
    },
    fetchSemester() {
      let that = this;
      this.semester.push({
        id: "0",
        value: "0",
        text: "Pilih Semester",
        selected: false,
      });
      master.Semester.getAllActive().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              id_smt: item.id_smt,
              nm_smt: item.nm_smt,
              smt: item.smt,
              id_thn_ajaran: item.id_thn_ajaran,
              thn_ajaran: item.thn_ajaran,
            };

            if (item?.a_periode_aktif == "1") {
              if (that.smt.a_periode_aktif == "0")
                that.smt.a_periode_aktif = item.id_smt;
            }

            that.semester.push(newoption);
          });
        }
      });
    },
    simpanConfigUjian() {
      let that = this;
      Promise.all([
        master.Konfigurasi.insertData({
          nama: "input_uts",
          value: this.konfig.input_uts ? 1 : 0,
        }),
        master.Konfigurasi.insertData({
          nama: "input_uas",
          value: this.konfig.input_uas ? 1 : 0,
        }),
      ]).then((res) => {
        if (res[0].status === 200) {
          that.statusConfUjian = {
            status: true,
            message: "Pengaturan Penilaian Ujian berhasil disimpan!",
          };
        }
      });
    },
    simpanConfigRilis() {
      let that = this;
      let rsem = this.konfig.rilis_semester;
      if (rsem.toString() !== "0") {
        this.konfig["rilis_uts_" + rsem] = this.konfig.rilis_uts;
        this.konfig["rilis_uas_" + rsem] = this.konfig.rilis_uas;
        this.konfig["rilis_jadwal_kuliah_" + rsem] =
          this.konfig.rilis_jadwal_kuliah;
        this.konfig["rilis_jadwal_dosen_" + rsem] =
          this.konfig.rilis_jadwal_dosen;
      }
      Promise.all([
        master.Konfigurasi.insertData({
          nama: "rilis_jadwal_dosen_" + rsem,
          value: this.konfig.rilis_jadwal_dosen ? 1 : 0,
        }),
        master.Konfigurasi.insertData({
          nama: "rilis_jadwal_kuliah_" + rsem,
          value: this.konfig.rilis_jadwal_kuliah ? 1 : 0,
        }),

        master.Konfigurasi.insertData({
          nama: "rilis_uts_" + rsem,
          value: this.konfig.rilis_uts ? 1 : 0,
        }),
        master.Konfigurasi.insertData({
          nama: "rilis_uas_" + rsem,
          value: this.konfig.rilis_uas ? 1 : 0,
        }),
      ]).then((res) => {
        if (res[0].status === 200) {
          that.statusConfRilis = {
            status: true,
            message: "Pengaturan Rilis Jadwal dan Nilai berhasil disimpan!",
          };
        }
      });
    },
    simpanConfigSidang() {
      let that = this;
      Promise.all([
        master.Konfigurasi.insertData({
          nama: "tgl_sidang_skripsi",
          value: this.konfig.tgl_sidang_skripsi,
        }),
        master.Konfigurasi.insertData({
          nama: "tgl_sidang_kp",
          value: this.konfig.tgl_sidang_kp,
        }),
        master.Konfigurasi.insertData({
          nama: "tgl_batas_input_pembimbing_kp",
          value: this.konfig.tgl_batas_input_pembimbing_kp,
        }),
        master.Konfigurasi.insertData({
          nama: "input_penguji",
          value: this.konfig.input_penguji ? 1 : 0,
        }),
        master.Konfigurasi.insertData({
          nama: "input_pembimbing",
          value: this.konfig.input_pembimbing ? 1 : 0,
        }),
      ]).then((res) => {
        if (res[0].status === 200) {
          that.statusConfSidang = {
            status: true,
            message: "Pengaturan Sidang berhasil disimpan!",
          };
        }
      });
    },

    simpanConfigBimbingan() {
      let that = this;
      Promise.all([
        master.Konfigurasi.insertData({
          nama: "tgl_batas_bimbingan_skripsi",
          value: this.konfig.tgl_batas_bimbingan_skripsi,
        }),
        master.Konfigurasi.insertData({
          nama: "tgl_batas_bimbingan_kp",
          value: this.konfig.tgl_batas_bimbingan_kp,
        }),
        master.Konfigurasi.insertData({
          nama: "min_bimbingan_skripsi",
          value: this.konfig.min_bimbingan_skripsi,
        }),
        master.Konfigurasi.insertData({
          nama: "min_bimbingan_kp",
          value: this.konfig.min_bimbingan_kp,
        }),
        master.Konfigurasi.insertData({
          nama: "min_bimbingan_skripsi_d3",
          value: this.konfig.min_bimbingan_skripsi_d3,
        }),
        master.Konfigurasi.insertData({
          nama: "min_bimbingan_kp_d3",
          value: this.konfig.min_bimbingan_kp_d3,
        }),
      ]).then((res) => {
        if (res[0].status === 200) {
          that.statusConfBimbingan = {
            status: true,
            message: "Pengaturan Bimbingan berhasil disimpan!",
          };
        }
      });
    },
  },
};
</script>

<style scoped></style>
