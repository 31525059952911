const cp = require("child_process");
const os = require("os");

export const cpname = () => {
  switch (process.platform) {
    case "win32":
      return process.env.COMPUTERNAME;
    case "darwin":
      return cp.execSync("scutil --get ComputerName").toString().trim();
    default:
      return (
        os.hostname() + ":" + os.type().replace(/ /g, "_") + ":" + os.release()
      );
  }
};

export const plname = () => {
  return os.platform();
};
