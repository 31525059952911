<template>
  <div class="intro-y box p-5">
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
      <form
        id="tabulator-html-filter-form"
        class="xl:flex sm:mr-auto"
        @submit.prevent="onFilter"
      >
        <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
            >Value</label
          >
          <input
            id="tabulator-html-filter-value"
            v-model="filter.value"
            type="text"
            class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
            autocomplete="off"
            placeholder="Search..."
          />
        </div>
        <div class="mt-2 xl:mt-0">
          <button
            id="tabulator-html-filter-go"
            type="button"
            class="btn btn-primary w-full sm:w-16"
            @click="onFilter"
          >
            Go
          </button>
        </div>
      </form>
    </div>
    <div class="overflow-x-auto scrollbar-hidden">
      <div
        id="tabulator"
        ref="tableRef"
        class="mt-5 table-report table-report--tabulator"
      ></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { tblTabulator } from "@/utils/tabulator";
import feather from "feather-icons";
import { master } from "@/services/models";

export default defineComponent({
  setup(props, context) {
    let deletingRow = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "query",
      type: "like",
      value: "",
    });
    const aturTeam = (e, cell) => {
      context.emit("dataUserSelected", cell.getData());
    };

    let panel = reactive({ panelOpen: ref() });
    let aktMhs = reactive({
      anggota_akt_mhs: ref(),
      bimbing_mhs: ref(),
    });
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          hozAlign: "left",
          resizable: false,
          headerSort: false,
        },
        // For HTML table
        {
          title: "Kode",
          width: 120,
          field: "uid",
          hozAlign: "left",
          headerSort: false,
        },
        {
          title: "Name",
          field: "name",
          hozAlign: "left",
          headerSort: false,
        },
        {
          title: "Aksi",
          responsive: 1,
          width: 120,
          hozAlign: "left",
          headerSort: false,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex btn btn-sm btn-primary items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Atur Team
                </a>
              </div>`);
            return a[0];
          },
          cellClick: aturTeam,
        },
      ];

      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        master.Users.endpoint,
        columns
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, "like", filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "query";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      deletingRow,
      idData,
      panel,
      aktMhs,
    };
  },
});
</script>
