<template>
  <div class="flex text-center border-t border-gray-200 border-dashed pt-1.5">
    <div class="flex-1 text-xs items-center">
      Profesi
      <div class="text-sm text-theme-19">52</div>
    </div>
    <div class="flex-1 text-xs items-center">
      Magister
      <div class="text-sm text-theme-23">53</div>
    </div>
    <div class="flex-1 text-xs items-center">
      Doktor
      <div class="text-sm text-theme-24">800</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JumlahGelar",
};
</script>

<style scoped></style>
