<template>
  <div>
    <div class="grid grid-cols-2 gap-2">
      <div class="intro-y box shadow rounded-lg">
        <h1 class="border-b p-5 text-lg font-bold">Biaya Kuliah</h1>
        <div class="p-3">
          <LayoutMenuBiayaKuliah></LayoutMenuBiayaKuliah>
        </div>
      </div>
      <div class="intro-y box shadow rounded-lg">
        <h1 class="border-b p-5 text-lg font-bold">Keuangan Mahasiswa</h1>
        <div class="p-3">
          <LayoutMenuKeuanganMhs></LayoutMenuKeuanganMhs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutMenuKeuanganMhs from "@/views/dashboard-mdp/layout-adm/LayoutMenuKeuanganMhs";
import LayoutMenuBiayaKuliah from "@/views/dashboard-mdp/layout-adm/LayoutMenuBiayaKuliah";
export default {
  name: "Main",
  components: { LayoutMenuBiayaKuliah, LayoutMenuKeuanganMhs },
};
</script>

<style scoped></style>
