<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Switch Role To...</h2>
    </div>
    <div class="post intro-y overflow-hidden box mt-5 p-3">
      <form class="flex" @submit.prevent="switchRole">
        <input
          v-model="model.uid"
          type="text"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
          placeholder="UID"
          required
        />

        <button
          class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 ml-2 align-top"
        >
          Login
        </button>
      </form>
      <div class="intro-x mt-8">
        <div v-if="showError" class="intro-x">
          <AlertFailed :message="loginError" :dismissable="true" />
        </div>
        <div v-if="loadingStatus" class="intro-x mt-4">
          <LoadingSpinner :message="'Logging in... Please wait...'" />
        </div>
      </div>
    </div>

    <div
      id="pickteam-modal"
      class="modal"
      :class="showPick ? 'overflow-y-auto show' : 'hide'"
      tabindex="-1"
      data-backdrop="static"
      aria-hidden="false"
      :style="
        showPick
          ? 'margin-top: 0px; margin-left: 0px; padding-left: 0px; z-index: 10000;'
          : ''
      "
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
              <div class="text-3xl mt-5">Silakan Login Sebagai</div>
            </div>
            <div class="px-5 pb-8 text-center">
              <ul class="inline-flex space-x-4">
                <li v-for="team in teams" :key="team.id">
                  <button
                    type="button"
                    class="btn btn-lg btn-success"
                    @click="setTeam(team.id)"
                  >
                    {{ team?.name }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
import { mapActions } from "vuex";
import LoadingSpinner from "@/components/loading-spinner/Main";
import AlertFailed from "@/components/alerts/AlertFailed";

export default defineComponent({
  name: "SwitchRole",
  components: { AlertFailed, LoadingSpinner },
  data() {
    return {
      model: {
        uid: "",
        apps: store.getters["auth/tokenName"],
      },
      showError: false,
      showPick: false,
    };
  },
  computed: {
    loginError() {
      return this.$store.state.auth.error;
    },
    pickTeam() {
      return this.$store.getters["auth/pickTeam"];
    },
    teams() {
      return this.$store.getters["userdata/teams"];
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    loginError() {
      this.showError = true;
    },
    pickTeam: {
      handler(nv) {
        if (nv) this.showPick = true;
      },
      immediate: true,
    },
  },
  mounted() {
    //this.showPick = false;
  },
  methods: {
    ...mapActions({
      SwitchRole: "auth/SwitchRole",
      SetTeam: "auth/SetTeam",
    }),
    switchRole: function () {
      this.SwitchRole(this.model).then(() => {
        this.showError = false;
      });
    },
    setTeam(id) {
      this.SetTeam({ id: id, refresh: true });
    },
    cancelPick() {
      this.showPick = false;
      store.commit("auth/pickTeam", false);
    },
  },
});
</script>

<style scoped></style>
