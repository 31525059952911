<template>
  <div>
    <select v-model="smt" :class="cssclass" @change="setCurrent">
      <option
        v-for="smtr in semester"
        :key="smtr.id"
        :value="smtr.value"
        :selected="smtr.selected"
      >
        {{ smtr.text }}
      </option>
    </select>
  </div>
</template>

<script>
import { master } from "@/services/models";

export default {
  name: "SemesterSelector",
  props: {
    cssclass: {
      type: String,
      default: "w-auto form-select mb-3 box md:w-full",
    },
  },
  data() {
    return {
      semester: [],
      smt: 0,
      current: null,
    };
  },
  computed: {
    currentSemester() {
      return this.$store.state.main.currentSemester;
    },
  },
  watch: {
    currentSemester: {
      handler: function (newdata) {
        this.smt = newdata.value;
      },
    },
  },
  mounted() {
    this.current = this.$store.state.main.currentSemester;
    this.fetchSemester();
  },
  methods: {
    setCurrent() {
      const csmt = this.smt;
      let curr = this.semester.filter((item) => item.id === csmt);
      this.$store.dispatch("main/currentSemester", curr[0]);
    },
    fetchSemester() {
      let that = this;
      this.semester.push({
        id: "0",
        value: "0",
        text: "Pilih Semester",
        selected: false,
      });
      master.Semester.getAllActive().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              id_smt: item.id_smt,
              nm_smt: item.nm_smt,
              smt: item.smt,
              id_thn_ajaran: item.id_thn_ajaran,
              thn_ajaran: item.thn_ajaran,
              selected: that.current?.id_smt === item.id_smt,
            };

            if (newoption.selected === true) that.smt = item.id_smt;
            if (item?.a_periode_aktif == "1") {
              if (item.id_smt != that.currentSemester?.id_smt)
                that.smt = that.currentSemester?.id_smt;
              else that.smt = item.id_smt;
            }

            if (that.currentSemester?.id_smt === undefined) {
              that.$store.dispatch("main/currentSemester", newoption);
            }
            that.semester.push(newoption);
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
