<template>
  <div>
    <div class="grid xl:grid-cols-2 grid-cols-1 gap-1 mb-3">
      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'permohonan-akademik' }">
          <div class="flex flex-col text-center">
            <BookOpenIcon
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Permohonan Akademik
          </div>
        </router-link>
      </div>
      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'permohonan-keuangan' }">
          <div class="flex flex-col text-center">
            <CreditCardIcon
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Permohonan Keuangan
          </div>
        </router-link>
      </div>
    </div>
    <div class="grid xl:grid-cols-3 grid-cols-1 gap-1">
      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'tagihan-wisuda-create' }">
          <div class="flex flex-col text-center">
            <GraduationCap
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Biaya Wisuda
          </div>
        </router-link>
      </div>

      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'validasi-pengisian-krs' }">
          <div class="flex flex-col text-center">
            <Flag
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Validasi Pengisian KRS
          </div>
        </router-link>
      </div>

      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'buka-tutup-absen' }">
          <div class="flex flex-col text-center whitespace-normal">
            <BallotCheck
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Buka/Tutup Absen
          </div>
        </router-link>
      </div>
    </div>

    <div class="grid xl:grid-cols-2 grid-cols-1 gap-1 mt-3">
      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'nota-bernomor' }">
          <div class="flex flex-col text-center">
            <FileTextIcon
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Buat Bukti Transaksi Penerimaan (Nota)
          </div>
        </router-link>
      </div>

      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'rekap-nota-bernomor' }">
          <div class="flex flex-col text-center">
            <FileTextIcon
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Rekap Nota
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import GraduationCap from "../../../assets/icons/graduationCap";
import Flag from "../../../assets/icons/flag";
import BallotCheck from "../../../assets/icons/ballotCheck";
export default {
  name: "LayoutMenuKeuanganMhs",
  components: { GraduationCap, Flag, BallotCheck },
};
</script>

<style scoped></style>
