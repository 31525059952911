<template>
  <div>
    <div class="grid xl:grid-cols-2 grid-cols-1 gap-1 mb-3">
      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'honor-ngajar' }">
          <div class="flex flex-col text-center">
            <ChalkboardUser
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Honor Mengajar
          </div>
        </router-link>
      </div>

      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'rekap-dosbing' }">
          <div class="flex flex-col text-center">
            <ChalkboardUser
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Dosen Pembimbing
          </div>
        </router-link>
      </div>

      <div
        class="rounded flex items-center justify-center border dark:border-gray-700 hover:shadow-sm p-2 hover:bg-gray-200"
      >
        <router-link :to="{ name: 'rekap-dosenpenguji' }">
          <div class="flex flex-col text-center">
            <ChalkboardUser
              class="text-theme-1 dark:text-white block w-6 h-6 mb-2 mx-auto"
            />
            Dosen Penguji
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ChalkboardUser from "../../../assets/icons/chalkboardUser";
export default {
  name: "LayoutMenuPTK",
  components: { ChalkboardUser },
};
</script>

<style scoped></style>
