<template>
  <div>
    <div class="grid grid-cols-2 gap-3">
      <div class="col-span-1">
        <TeamUserTabulator @dataUserSelected="userSelectedTabulator" />
      </div>
      <div v-if="userSelected?.id" class="col-span-1">
        <div class="grid grid-cols-2 gap-1">
          <div class="col-span-1">
            <div class="font-bold text-lg mb-4">Related User</div>
            <div>
              <label class="font-bold text-lg italic">
                {{ userSelected.uid }} | {{ userSelected.name }}
              </label>
            </div>
            <div class="input-form mt-3">
              <label>User Teams</label>
              <div
                v-for="(item, index) in listTeam"
                :key="index"
                class="form-check mt-2"
              >
                <input
                  :id="'team-' + index"
                  v-model="item.selected"
                  class="form-check-input"
                  type="checkbox"
                />
                <label class="form-check-label" :for="'team-' + index">
                  {{ item.name }}
                </label>
              </div>
            </div>
            <div class="mt-5">
              <button class="btn btn-primary" @click="submit">Simpan</button>
            </div>
          </div>

          <div class="col-span-1">
            <div class="font-bold text-lg mb-4">Related Apps</div>

            <TailSelect
              v-model="related_app"
              :options="{
                search: true,
                descriptions: true,
                hideSelected: true,
                hideDisabled: true,
                classNames: 'w-full mb-3',
                multiShowCount: false,
                multiContainer: true,
              }"
              multiple
            >
              <option
                v-for="(item, index) in apps"
                :key="index"
                :value="item.value"
              >
                {{ item.text }}
              </option>
            </TailSelect>

            <AlertSuccess
              v-if="alertSuccess"
              :message="messageAlert"
              class="mt-3"
            />
            <AlertFailed v-if="alertFailed" :message="messageAlert" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { master } from "../../../services/models/master";
import AlertSuccess from "../../../components/alerts/AlertSuccess.vue";
import AlertFailed from "../../../components/alerts/AlertFailed.vue";
import TeamUserTabulator from "./TeamUserTabulator.vue";
export default {
  components: {
    AlertSuccess,
    AlertFailed,
    TeamUserTabulator,
  },
  data() {
    return {
      listUser: [],
      listTeam: [],
      userSelected: [],
      related_app: [],
      apps: [],
      user: null,
      messageTable: "Belum ada data",
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
    };
  },
  watch: {},
  mounted() {
    this.getTeam();
    this.getApps();
  },
  methods: {
    async getApps() {
      master.Apps.getAll().then((res) => {
        let tmpApps = [];
        if (res.status === 200) {
          res.data.forEach((element) => {
            tmpApps.push({
              id: element.id,
              value: element.id,
              text: element.nama,
            });
          });
          this.apps = tmpApps;
        }
      });
    },
    async getTeam() {
      master.Team.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.listTeam.push({
              ...element,
              selected: false,
            });
          });
        }
      });
    },
    userSelectedTabulator(data) {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.listTeam.forEach((item, index) => {
        this.listTeam[index].selected = false;
      });
      this.userSelected = data;
      let currentUserTeam = data.team_user;
      currentUserTeam.forEach((item) => {
        let currentTeamId = item.team.id;
        let index = this.listTeam.findIndex((elem) => elem.id == currentTeamId);
        this.listTeam[index].selected = true;
      });
      if (data.ability_user.length > 0) {
        this.related_app = data.ability_user?.map((res) => {
          return res.apps_id.toString();
        });
      } else {
        this.related_app = [];
      }
    },
    submit() {
      this.alertSuccess = false;
      this.alertFailed = false;
      let data = {
        userId: this.userSelected?.id,
        teams: this.listTeam.filter((item) => {
          if (item.selected == true) {
            return item.id;
          }
        }),
        apps: this.related_app,
      };
      master.Team.insertData(data).then((res) => {
        if (res.status === 200) {
          if (res.data.code == 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;
          } else {
            this.alertFailed = true;
            this.messageAlert = "Validasi Error";
          }
        }
      });
    },
  },
};
</script>
