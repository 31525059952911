<template>
  <div class="grid grid-cols-3 gap-4">
    <div class="span-cols-1">
      <div class="intro-x flex items-center mb-5">
        <h2 class="text-md font-medium truncate">Default</h2>
      </div>
      <div class="mb-3">
        <span class="form-label">Institusi Default</span>
        <InstitusiSelector :cssclass="'form-select mt-2 sm:mr-2'" />
      </div>
      <div class="mb-3">
        <span class="form-label">Fakultas Default</span>
        <FakultasSelector :cssclass="'form-select mt-2 sm:mr-2'" />
      </div>
    </div>
    <div class="span-cols-1">
      <div class="intro-x flex items-center mb-5">
        <h2 class="text-md font-medium truncate mr-auto">Semester Aktif</h2>
      </div>
      <div class="mb-3">
        <span class="form-label">Semester Perkuliahan</span>
        <select v-model="smt.a_periode_aktif" class="form-select mt-2 sm:mr-2">
          <option v-for="smtr in semester" :key="smtr.id" :value="smtr.value">
            {{ smtr.text }}
          </option>
        </select>
        <div
          v-if="statusSmtAktif"
          class="mt-2"
          :class="statusSmtAktif.status ? 'text-theme-9' : 'text-theme-12'"
        >
          {{ statusSmtAktif.message }}
        </div>
      </div>
      <div class="mb-3">
        <span class="form-label">Semester Skripsi</span>
        <select v-model="smt.semester_skripsi" class="form-select mt-2 sm:mr-2">
          <option v-for="smtr in semester" :key="smtr.id" :value="smtr.value">
            {{ smtr.text }}
          </option>
        </select>
        <div
          v-if="statusSmtSkripsi"
          class="mt-2"
          :class="statusSmtSkripsi.status ? 'text-theme-9' : 'text-theme-12'"
        >
          {{ statusSmtSkripsi.message }}
        </div>
      </div>
      <div class="mb-3">
        <span class="form-label">Semester Keja Praktik</span>
        <select v-model="smt.semester_kp" class="form-select mt-2 sm:mr-2">
          <option v-for="smtr in semester" :key="smtr.id" :value="smtr.value">
            {{ smtr.text }}
          </option>
        </select>
        <div
          v-if="statusSmtKp"
          class="mt-2"
          :class="statusSmtKp.status ? 'text-theme-9' : 'text-theme-12'"
        >
          {{ statusSmtKp.message }}
        </div>
      </div>
      <div>
        <button class="btn btn-success" @click="simpanConfig">
          <SaveIcon class="w-4 h-4 mr-1"></SaveIcon>
          Simpan
        </button>
      </div>
    </div>
    <div class="intro-y span-cols-1">
      <div class="mr-auto">
        <router-link
          :to="{
            name: 'semester',
          }"
          class="btn btn-default shadow-md mr-2"
        >
          <DatabaseIcon class="w-4 mr-2"></DatabaseIcon>
          Master Semester
        </router-link>
        <router-link
          :to="{
            name: 'ruangan',
          }"
          class="btn btn-default shadow-md"
        >
          <DatabaseIcon class="w-4 mr-2"></DatabaseIcon>
          Master Ruang
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import FakultasSelector from "../../components/form/FakultasSelector";
import InstitusiSelector from "../../components/form/InstitusiSelector";
import { master } from "@/services/models";
export default {
  name: "GeneralConfig",
  components: {
    FakultasSelector,
    InstitusiSelector,
  },
  data() {
    return {
      semester: [],
      smt: { a_periode_aktif: 0 },
      statusSmtAktif: {},
      statusSmtSkripsi: {},
      statusSmtKp: {},
    };
  },
  mounted() {
    this.fetchSemester();
  },
  beforeMount() {
    this.getConfig();
  },
  methods: {
    fetchSemester() {
      let that = this;
      this.semester.push({
        id: "0",
        value: "0",
        text: "Pilih Semester",
        selected: false,
      });
      master.Semester.getAllActive().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              id_smt: item.id_smt,
              nm_smt: item.nm_smt,
              smt: item.smt,
              id_thn_ajaran: item.id_thn_ajaran,
              thn_ajaran: item.thn_ajaran,
            };

            if (item?.a_periode_aktif == "1") {
              if (that.smt.a_periode_aktif == "0")
                that.smt.a_periode_aktif = item.id_smt;
            }

            that.semester.push(newoption);
          });
        }
      });
    },

    getConfig() {
      master.Konfigurasi.getAll().then((result) => {
        if (result.status === 200) {
          let konfsmt = result.data.filter((a) => {
            return a.nama === "semester_skripsi" || a.nama === "semester_kp";
          });
          konfsmt.forEach((a) => {
            this.smt[a.nama] = a.value;
          });
        }
      });
    },
    simpanConfig() {
      let that = this;
      Promise.all([
        master.Konfigurasi.insertData({
          nama: "semester_skripsi",
          value: this.smt.semester_skripsi,
        }),
        master.Konfigurasi.insertData({
          nama: "semester_kp",
          value: this.smt.semester_kp,
        }),
        master.Semester.postCustomPath(
          "update_active/" + this.smt.a_periode_aktif,
          {
            id: this.smt.a_periode_aktif,
          }
        ),
      ]).then(function (results) {
        if (results[0].status === 200) {
          that.statusSmtSkripsi = {
            status: true,
            message: "Semester skripsi aktif berhasil diupdate!",
          };
        } else {
          that.statusSmtSkripsi = {
            status: false,
            message: "Semester skripsi aktif gagal diupdate!",
          };
        }

        if (results[1].status === 200) {
          that.statusSmtKp = {
            status: true,
            message: "Semester KP aktif berhasil diupdate!",
          };
        } else {
          that.statusSmtKp = {
            status: false,
            message: "Semester KP aktif gagal diupdate!",
          };
        }

        if (results[2].status === 200) {
          that.statusSmtAktif = {
            status: true,
            message: results[2].data.message,
          };
        } else {
          that.statusSmtAktif = {
            status: false,
            message: "Semester aktif gagal diupdate!",
          };
        }
      });
    },
  },
};
</script>

<style scoped></style>
