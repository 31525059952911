<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-3">
        <!-- BEGIN: Mahasiswa Report -->
        <div class="col-span-12 lg:col-span-6 xl:col-span-5 mt-8">
          <div class="intro-y block sm:flex items-center h-10">
            <select class="sm:ml-auto mt-3 sm:mt-0 sm:w-auto form-select box">
              <option value="daily">Penerimaan Mahasiswa Baru</option>
              <option value="weekly">Mahasiswa Aktif</option>
              <option value="monthly">Mahasiswa Lulus</option>
              <option value="yearly">Mahasiswa D.O</option>
            </select>
          </div>
          <div class="report-box-2 intro-y mt-12 sm:mt-5">
            <div class="box sm:flex">
              <div class="px-8 py-10 flex flex-col justify-center flex-1">
                <UsersIcon class="w-10 h-10 text-theme-12" />
                <div class="text-base text-gray-600 mt-3 mb-2">
                  Mahasiswa Aktif
                </div>
                <div class="relative text-3xl font-bold">54.143</div>
                <div
                  class="text-gray-600 dark:text-gray-600 text-xs border-b border-gray-200 flex mb-2 pb-2 mt-3 mb-2"
                ></div>

                <div class="text-gray-600 dark:text-gray-600 text-xs">
                  Fakultas 1
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">4.501</div>
                </div>
                <div class="text-gray-600 dark:text-gray-600 text-xs mt-5">
                  Fakultas 2
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">2</div>
                </div>
              </div>
              <div
                class="px-5 flex flex-col justify-center flex-1 border-t sm:border-t-0 sm:border-l border-gray-300 dark:border-dark-5 border-dashed"
              >
                <div class="text-gray-600 dark:text-gray-600 text-xs">
                  Prodi 1
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">4.501</div>
                </div>
                <div class="text-gray-600 dark:text-gray-600 text-xs mt-3">
                  Prodi 2
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">485</div>
                </div>
                <div class="text-gray-600 dark:text-gray-600 text-xs mt-3">
                  Prodi 2
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">78</div>
                </div>
                <div class="text-gray-600 dark:text-gray-600 text-xs mt-3">
                  Prodi 2
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">7854</div>
                </div>
                <div class="text-gray-600 dark:text-gray-600 text-xs mt-3">
                  Prodi 2
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">115</div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-xs flex mb-2 pb-2 mt-6 mb-2">
            <a
              href="/"
              class="intro-x w-full block text-center rounded-md py-3 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
              >Show Detail</a
            >
          </div>
        </div>

        <!-- BEGIN: Jumlah Dosen Report -->
        <div class="col-span-12 lg:col-span-6 xl:col-span-7 mt-3">
          <div class="report-box-2 intro-y mt-12 sm:mt-5">
            <div class="box sm:flex">
              <div class="px-8 py-10 flex flex-col justify-center flex-1">
                <UsersIcon class="w-10 h-10 text-theme-6" />
                <div class="text-base text-gray-600 mt-3 mb-2">Dosen Aktif</div>
                <div class="relative text-3xl font-bold">54.143</div>
                <div
                  class="text-gray-600 dark:text-gray-600 text-xs border-b border-gray-200 flex mb-2 pb-2 mt-3 mb-2"
                ></div>

                <div class="text-gray-600 dark:text-gray-600 flex text-xs">
                  Fakultas 1
                  <div class="ml-auto text-base">4.501</div>
                </div>
                <JumlahGelar></JumlahGelar>
                <div class="text-gray-600 dark:text-gray-600 flex text-xs mt-4">
                  Fakultas 2
                  <div class="ml-auto text-base">45</div>
                </div>
                <JumlahGelar></JumlahGelar>
              </div>
              <div
                class="py-8 px-6 flex flex-col justify-center flex-1 border-t sm:border-t-0 sm:border-l border-gray-300 dark:border-dark-5 border-dashed"
              >
                <div class="text-gray-600 dark:text-gray-600 flex text-xs">
                  Prodi 1
                  <div class="ml-auto text-base">51</div>
                </div>
                <JumlahGelar></JumlahGelar>
                <div class="text-gray-600 dark:text-gray-600 flex text-xs mt-4">
                  Prodi 2
                  <div class="ml-auto text-base">50</div>
                </div>
                <JumlahGelar></JumlahGelar>
                <div class="text-gray-600 dark:text-gray-600 flex text-xs mt-4">
                  Prodi 3
                  <div class="ml-auto text-base">33</div>
                </div>
                <JumlahGelar></JumlahGelar>
                <div class="text-gray-600 dark:text-gray-600 flex text-xs mt-4">
                  Prodi 4
                  <div class="ml-auto text-base">4</div>
                </div>
                <JumlahGelar></JumlahGelar>

                <div class="text-gray-600 dark:text-gray-600 flex text-xs mt-4">
                  Prodi 5
                  <div class="ml-auto text-base">5</div>
                </div>
                <JumlahGelar></JumlahGelar>
              </div>
            </div>
          </div>
        </div>

        <!-- BEGIN: General Report -->
        <div class="col-span-12">
          <div class="intro-y flex items-center h-10 mt-8">
            <h2 class="text-lg font-medium truncate mr-auto">Report</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <SemesterSelector></SemesterSelector>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-4 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <BookOpenIcon class="report-box__icon text-theme-9 mr-10" />
                    <a
                      href="/"
                      class="intro-x flex-1 ml-auto text-center py-1 rounded-md border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
                      >Show Detail</a
                    >
                  </div>
                  <div class="flex">
                    <div class="flex-col">
                      <div class="text-3xl font-bold leading-8 mt-6">2.149</div>
                      <div class="text-base text-gray-600 mt-1">
                        Total Publikasi
                      </div>
                    </div>
                    <div class="flex-col text-center ml-auto">
                      <div class="text-3xl font-bold leading-8 mt-6">2.149</div>
                      <div class="text-base text-gray-600 mt-1">
                        Total Penelitian
                      </div>
                    </div>
                    <div class="flex-col ml-auto text-right">
                      <div class="text-3xl font-bold leading-8 mt-6">2.149</div>
                      <div class="text-base text-gray-600 mt-1">
                        Total Pengabdian
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-2 xl:col-span-2 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UserXIcon class="report-box__icon text-theme-6 mr-10" />
                    <a
                      href="/"
                      class="intro-x flex-1 ml-auto text-center py-1 rounded-md border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
                      >Show Detail</a
                    >
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">152.040</div>
                  <div class="text-base text-gray-600 mt-1">Kerjasama</div>
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-6 xl:col-span-6 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <a
                      href="/"
                      class="intro-x flex-1 ml-auto text-center py-1 rounded-md border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
                      >Show Detail</a
                    >
                  </div>
                  <div class="flex">
                    <div class="flex-col">
                      <div class="text-3xl font-bold leading-8 mt-6">2.149</div>
                      <div class="text-base text-gray-600 mt-1">Guru Besar</div>
                    </div>
                    <div class="flex-col text-center ml-auto">
                      <div class="text-3xl font-bold leading-8 mt-6">2.149</div>
                      <div class="text-base text-gray-600 mt-1">
                        Lektor Kepala
                      </div>
                    </div>
                    <div class="flex-col text-center ml-auto">
                      <div class="text-3xl font-bold leading-8 mt-6">2.149</div>
                      <div class="text-base text-gray-600 mt-1">Lektor</div>
                    </div>
                    <div class="flex-col ml-auto text-right">
                      <div class="text-3xl font-bold leading-8 mt-6">2.149</div>
                      <div class="text-base text-gray-600 mt-1">
                        Asisten Ahli
                      </div>
                    </div>
                    <div class="flex-col ml-auto text-right">
                      <div class="text-3xl font-bold leading-8 mt-6">2.149</div>
                      <div class="text-base text-gray-600 mt-1">
                        Tenaga Pengajar
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
      </div>
    </div>
  </div>
</template>

<script>
import SemesterSelector from "@/components/form/SemesterSelector";
import JumlahGelar from "@/views/dashboard-mdp/component/JumlahGelar";
export default {
  name: "GeneralReport",
  components: {
    JumlahGelar,
    SemesterSelector,
  },
};
</script>

<style scoped></style>
