<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Pengaturan</h2>
    </div>
    <div class="post intro-y overflow-hidden box mt-5">
      <div
        class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
        role="tablist"
      >
        <TippyTabNav
          :tab-nav-id="'general'"
          :content="'Pengaturan Umum'"
          :active-tab="
            activeTab == '' || activeTab === 'general' ? 'active' : ''
          "
          :tab-label="'general'"
          @click="activeTab = 'general'"
        >
          <FileTextIcon class="w-4 h-4 mr-2" /> General
        </TippyTabNav>

        <TippyTabNav
          :tab-nav-id="'penilaian'"
          :content="'Pengaturan Pinilaian dan Penjadwalan'"
          :active-tab="activeTab === 'penilaian' ? 'active' : ''"
          :tab-label="'penilaian'"
          @click="activeTab = 'penilaian'"
        >
          <AlignLeftIcon class="w-4 h-4 mr-2" /> Penilaian & Jadwal
        </TippyTabNav>

        <Tippy
          id="sync-tab"
          tag="a"
          content="Sinkronisasi Data SIAKAD dan PDDIKTI"
          data-toggle="tab"
          data-target="#sync"
          href="javascript:;"
          class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          role="tab"
          :class="activeTab === 'sync' ? 'active' : ''"
          aria-selected="false"
          @click="activeTab = 'sync'"
        >
          <CodeIcon class="w-4 h-4 mr-2" /> Sinkronisasi Data
        </Tippy>
        <Tippy
          id="teamUser-tab"
          tag="a"
          content="Manage Team and User"
          data-toggle="tab"
          data-target="#teamUser"
          href="javascript:;"
          class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          :class="activeTab === 'teamUser' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="activeTab = 'teamUser'"
        >
          <AlignLeftIcon class="w-4 h-4 mr-2" /> Team and User
        </Tippy>
        <Tippy
          id="userManagement-tab"
          tag="a"
          content="Management User Ability"
          data-toggle="tab"
          data-target="#userManagement"
          href="javascript:;"
          class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          :class="activeTab === 'userManagement' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="activeTab = 'userManagement'"
        >
          <AlignLeftIcon class="w-4 h-4 mr-2" /> User Ability
        </Tippy>

        <Tippy
          id="managementTeamAbility-tab"
          tag="a"
          content="Management Team Ability"
          data-toggle="tab"
          data-target="#managementTeamAbility"
          href="javascript:;"
          class="w-full sm:w-52 py-4 text-center flex justify-center items-center"
          :class="activeTab === 'managementTeamAbility' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="activeTab = 'managementTeamAbility'"
        >
          <AlignLeftIcon class="w-4 h-4 mr-2" /> Team Ability
        </Tippy>
        <Tippy
          id="others-tab"
          tag="a"
          content="Use search keywords"
          data-toggle="tab"
          data-target="#others"
          href="javascript:;"
          class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          :class="activeTab === 'others' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="activeTab = 'others'"
        >
          <AlignLeftIcon class="w-4 h-4 mr-2" /> Lainnya
        </Tippy>
      </div>
      <div class="tab-content">
        <TabContent2
          :tab-id="'general'"
          :active-tab="
            activeTab == '' || activeTab === 'general' ? 'active' : ''
          "
        >
          <GeneralConfig
            v-if="activeTab == '' || activeTab === 'general'"
          ></GeneralConfig>
        </TabContent2>

        <TabContent2
          :tab-id="'penilaian'"
          :active-tab="activeTab === 'penilaian' ? 'active' : ''"
        >
          <FormAturPenilaian v-if="activeTab === 'penilaian'" />
        </TabContent2>

        <div
          id="sync"
          class="tab-pane p-5 max-w-full"
          :class="activeTab === 'sync' ? 'active' : ''"
          role="tabpanel"
          aria-labelledby="sync-tab"
        >
          <div
            v-if="activeTab === 'sync'"
            class="border border-gray-200 dark:border-dark-5 rounded-md p-5 h-screen"
          >
            <SyncFeeder></SyncFeeder>
            <div class="grid grid-cols-2 gap-4">
              <div class="mt-3">
                <table class="table">
                  <thead>
                    <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                      <th class="whitespace-nowrap">#</th>
                      <th class="whitespace-nowrap">Server</th>
                      <th class="whitespace-nowrap"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="hover:bg-gray-200">
                      <td class="border-b dark:border-dark-5">1</td>
                      <td class="border-b dark:border-dark-5">1215112163</td>
                      <td class="border-b dark:border-dark-5">
                        <button class="btn btn-sm btn-warning">
                          <Edit3Icon class="w-4 h-4 mr-2"></Edit3Icon>
                          Edit
                        </button>
                        <button class="btn btn-sm btn-danger ml-2">
                          <DeleteIcon class="w-4 h-4 mr-2"></DeleteIcon>
                          Hapus
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3 ml-2">
                <FormAturServerFeeder />
              </div>
            </div>
          </div>
        </div>
        <div
          id="userManagement"
          class="tab-pane p-5 max-w-full"
          :class="activeTab === 'userManagement' ? 'active' : ''"
          role="tabpanel"
          aria-labelledby="userManagement-tab"
        >
          <div
            v-if="activeTab === 'userManagement'"
            class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
          >
            <UserManagement />
          </div>
        </div>
        <div
          id="teamUser"
          class="tab-pane p-5 max-w-full"
          :class="activeTab === 'teamUser' ? 'active' : ''"
          role="tabpanel"
          aria-labelledby="teamUser-tab"
        >
          <div
            v-if="activeTab === 'teamUser'"
            class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
          >
            <TeamUser />
          </div>
        </div>
        <div
          id="managementTeamAbility"
          class="tab-pane p-5 max-w-full"
          :class="activeTab === 'managementTeamAbility' ? 'active' : ''"
          role="tabpanel"
          aria-labelledby="managementTeamAbility-tab"
        >
          <div
            v-if="activeTab === 'managementTeamAbility'"
            class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
          >
            <ManagementTeamAbility />
          </div>
        </div>
        <div
          id="others"
          class="tab-pane p-5 max-w-full"
          :class="activeTab === 'others' ? 'active' : ''"
          role="tabpanel"
          aria-labelledby="others-tab"
        >
          <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
            Pengaturan Lainnya
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SyncFeeder from "@/components/utility/SyncFeeder";
import FormAturPenilaian from "@/views/configuration/FormAturPenilaian";
import FormAturServerFeeder from "@/views/configuration/FormAturServerFeeder";

import UserManagement from "./Components/UserManagement.vue";
import TeamUser from "./Components/TeamUser.vue";
import ManagementTeamAbility from "./Components/ManagementTeamAbility.vue";
import TabContent2 from "@/components/tabs/TabContent2";
import GeneralConfig from "@/views/configuration/GeneralConfig";
import TippyTabNav from "@/components/tabs/TippyTabNav";

export default defineComponent({
  name: "Configuration",
  components: {
    TippyTabNav,
    GeneralConfig,
    TabContent2,
    FormAturServerFeeder,
    FormAturPenilaian,
    SyncFeeder,
    UserManagement,
    TeamUser,
    ManagementTeamAbility,
  },
  data() {
    return {
      activeTab: null,
    };
  },
  mounted() {
    this.activeTab = this.$route.params?.tab;
    if (this.activeTab === undefined) this.activeTab = "";
  },
});
</script>

<style scoped></style>
